import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import H1 from "../components/htmlElements/h1";
import Layout from "../components/layout";
import { getProjectName } from "../lib/functions";
import SearchBox from "../components/contentElements/searchBox";

export default function HelpPage() {
  const projectName = getProjectName(true);

  const { irisText, oasisText, irisSearchBox, oasisSearchBox } =
    useStaticQuery(graphql`
      query {
        irisText: markdownRemark(
          frontmatter: { pageId: { eq: "IRIS-about-page" } }
        ) {
          frontmatter {
            title
          }
          html
        }

        oasisText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-about-page" } }
        ) {
          frontmatter {
            title
          }
          html
        }

        irisSearchBox: markdownRemark(
          frontmatter: { pageId: { eq: "IRIS-searchBox" } }
        ) {
          frontmatter {
            title
          }
          html
        }

        oasisSearchBox: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-searchBox" } }
        ) {
          frontmatter {
            title
          }
          html
        }
      }
    `);

  let homepageContent = "";
  let searchBoxTitle = "";
  let searchBoxContent = "";

  if (irisText && projectName === "IRIS" && irisText.html) {
    homepageContent = irisText.html;
  }

  if (oasisText && projectName === "OASIS" && oasisText.html) {
    homepageContent = oasisText.html;
  }

  if (
    irisSearchBox &&
    projectName === "IRIS" &&
    irisSearchBox.frontmatter.title
  ) {
    searchBoxTitle = irisSearchBox.frontmatter.title;
  }

  if (
    oasisSearchBox &&
    projectName === "OASIS" &&
    oasisSearchBox.frontmatter.title
  ) {
    searchBoxTitle = oasisSearchBox.frontmatter.title;
  }

  if (irisSearchBox && projectName === "IRIS" && irisSearchBox.html) {
    searchBoxContent = irisSearchBox.html;
  }

  if (oasisSearchBox && projectName === "OASIS" && oasisSearchBox.html) {
    searchBoxContent = oasisSearchBox.html;
  }

  homepageContent = homepageContent.replaceAll(
    "<h2>",
    `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`
  );

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1
            innerContent={
              projectName === "OASIS"
                ? oasisText.frontmatter.title
                : irisText.frontmatter.title
            }
            additionalClasses="pb-5"
          />

          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: homepageContent,
            }}
          />
        </div>

        <div className="col-span-12 md:col-span-5">
          <SearchBox
            searchBoxTitle={searchBoxTitle}
            searchBoxContent={searchBoxContent}
          />
          <br />
        </div>
      </div>
    </Layout>
  );
}
